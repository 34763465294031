import * as React from "react"
import PropTypes, { nominalTypeHack } from "prop-types"
import "./layout.css"


const Video = ({ video }) => {
    let { width, text, title, description, srcUrl, duration, channelName, channelLink, orientation , alignment, videoMinWidth} = video
    return (
        <div className={"video " + orientation} style={{minHeight: 220, minWidth: width, width: "90%", display: "flex", margin: "25px auto", marginRight: alignment === "left" ? 25 : "auto", padding: 25, justifyContent: "center", boxShadow: "0px 0px 5px 1px rgb(0 0 0 / 7%)"}}>
            <div style={{marginRight: 15, display: "flex", flexDirection: "column"}}>
                <h6 style={{fontSize: "18px", marginTop: 0, marginBottom: 5}}>{title}</h6>
                <p className="small">{description}</p>
                <div className="very-small row" style={{marginTop: "auto", display: "flex"}}>
                    <p className="very-small">Von: &nbsp;</p>
                    <a className="very-small" href={channelLink}>{channelName}</a>
                    <p className="very-small" style={{marginLeft: 15}}>{"Dauer: " + duration}</p>
                </div>
            </div>
            <div className='youtube-wrapper' style={{margin: "auto", minWidth: orientation === "row" ? "50%" : "100%"}}>
                <div className={'youtube-container'} style={{ display: "flex", height: "auto", margin: "auto"}}>
                    <iframe
                        src={srcUrl}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
)}



export default Video
