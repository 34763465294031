import * as React from "react"

import Layout from "../components/layout"
import Inhaltsverzeichnis from "../components/inhaltsverzeichnis.jsx"

import Seo from "../components/seo"
import Button from "../components/button-link"
import AmazonItem from "../components/amazon-item"

import rare from '../images/hotwheels-rare-logo.png'
import carculture from '../images/car-culture.jpg'
import logo from '../images/hotwheels-logo.svg'

import bluecard from '../images/blue-card.webp'
import Video from "../components/video"

const Ratgeber = () => (
  <Layout>
    <Seo title="Hot Wheels Bahnen" description={"Ein Ratgeber für Hot Wheels Bahnen und Spielsets und einige Empfehlungen von uns."}>
      <script type='application/ld+json'>
        {
          `
          {
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.hotwheels.autos/",
              "url": "https://www.hotwheels.autos",
              "name": "HotWheels.autos",
              "inLanguage": "de-DE"
            },
            {
              "@type": "WebPage",
              "@id": "https://www.hotwheels.autos/bahnen",
              "url": "https://www.hotwheels.autos/bahnen",
              "name": "Die besten Hot Wheels Bahnen in 2022",
              "datePublished": "2022-08-09",
              "dateModified": "2022-08-16",
              "isPartOf": {
                  "@id": "https://www.hotwheels.autos"
              },
              "inLanguage": "de-DE"
            },
            {
              "@context": "https://schema.org/",
              "@type": "Article",
              "headline": "Die besten Hot Wheels Bahnen in 2022",
              "datePublished": "2022-08-05",
              "dateModified": "2022-08-16",
              "inLanguage": "de-DE",
              "image": {
                "@type": "ImageObject",
                "url": "https://m.media-amazon.com/images/I/71G5rZoxGTL._AC_SL1500_.jpg",
                "width": "941",
                "height": "1500"
              },
              "author": {
                "@type": "Person",
                "name": "Niklas Oelze"
              },
              "publisher": {
                "@type": "Organization",
                "name": "HotWheels.autos",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${logo}",
                  "width": "985",
                  "height": "346"
                }
              }
            },
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "url": "https://www.hotwheels.autos/",
              "numberOfItems": "6",
              "itemListElement": [
                {
                  "@type": "Product",
                  "name": "Hot Wheels Super Ultimate Garage",
                  "image": "https://m.media-amazon.com/images/I/81SrErCcyuS._AC_SL1500_.jpg",
                  "description": "Die größte Hot Wheels Garage, die Sie kaufen können. Es ist über <b>90 cm hoch</b> und kann etwa <b>140 verschiedene Hot Wheels Autos</b> in seinen mehrstöckigen Garagenplätzen unterbringen! Die Garage hat mehrstufige Rennstrecken und einen fantastischen Aufzug, der die Autos auf die oberste Ebene der Garage befördert. Es gibt einen Gorilla, der an der Seite des Aufzugs hochklettert und versucht, Autos aus dem Aufzug zu stoßen.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.6",
                    "reviewCount": "6"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-ftb69,--city-ultimate-parkgarage-und-parkhaus-für-kinder,-garage-mit-hai-für-+90-autos,-mit-looping-tracks-inkl.-2-spielzeugautos,-ca.-63-cm-hoch,-ab-5-jahren,-mehrfarbig/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "147",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Hot Wheels City Robo T-Rex Ultimate Garage",
                  "image": "https://m.media-amazon.com/images/I/71G5rZoxGTL._AC_SL1500_.jpg",
                  "description": "Ist genauso hoch wie die Super Garage hat aber nur Platz für 100 Autos. Dafür können sich die Autos auf der Zweispuren Bahn ein Wettrennen nach unten liefern, während sie einem T-Rex ausweichen müssen. Die Rennbahn hat auch mehrere Lücken in der Bahn für coole Luftsprünge.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.4",
                    "reviewCount": "156"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-gjl14,--city-robo-t-rex,-megacity-parkgarage-mit-t-rex-angriff,-mehrstöckig,-mehrspieler-modus,-platz-für-+100-fahrzeuge-im-maßstab-1:64,-spielzeug-geschenkidee-für-kinder-ab-3-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "92",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Hot Wheels City Cobra Crush",
                  "image": "https://m.media-amazon.com/images/I/61cVrs+DItL._AC_SL1500_.jpg",
                  "description": "Ein cooles Stunt Bahnset um die Stadt zu erweitern. Wie der Name schon sagt, handelt es sich bei dieser Strecke um eine riesige Kobra, auf die man Autos schießt. Die Kobra schließt ihr Maul, um ein Auto zu fangen, und du kannst das gefangene Auto retten, indem du ein Auto auf ihren Rücken schießt und einen Auslöser betätigst, der die Schlange einstürzen und ihr Maul öffnen lässt!",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "39"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-fnb20,--kobrangriff-spielset,-spielzeug-autorennbahn-ab-ab-4-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Hot Wheels City Ultimate Gator Car Wash",
                  "image": "https://m.media-amazon.com/images/I/81MiK1QwgVL._AC_SL1500_.jpg",
                  "description": "Die Alligatoren Waschanlage ist eins der coolsten Erweiterungen. Es gibt einen Aufzug, an dem man kurbelt, um das Auto nach oben zu bringen, es gibt einen Rädchen, an dem man drehen kann, damit echtes Wasser auf das Auto fällt, man kann dann das Auto die Strecke hinunter in einen Whirlpool schieben, dem man entkommen kann. Es gibt auch einen riesigen Alligator, der bereit ist, das Auto zu fressen!",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.0",
                    "reviewCount": "48"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-ftb67,--city-ultimative-autowaschanlage-mit-krokodil,-car-wash-waschstation-spielset-mit-farbwechseleffekt-inkl.-1-spielzeugauto-und-alligator,-spielzeug-autorennbahn-ab-4-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "83.62",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Hot Wheels Action MEGA Crash Superbahn",
                  "image": "https://m.media-amazon.com/images/I/71CqE9eToCL._AC_SL1500_.jpg",
                  "description": "Die Kinder können ihre Fahrzeuge auf der Bahn starten, und die Fahrzeuge können auf der Bahn kreisen bis sie in den drei verschiedenen Crash-Zonen einem aufregenden Zusammenstoß immer näher kommen. Mit zwei Abschussvorrichtungen können Kinder ihre Freunde herausfordern und weitere Fahrzeuge auf die Strecke bringen, bis sie in einem dramatischen Crash aufeinandertreffen, der zum Spaß dieses High-Speed-Sets gehört. Über eine Falltür in der Bahn kannst das Set mit anderen verbunden werden.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.4",
                    "reviewCount": "652"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-cdl45,--action-mega-crash-superbahn,-trackset-mit-loopings-und-kurven-inkl.-2-starter-und-1-spielzeugauto,-autorennbahn-ab-6-jahren-mehrfarbig/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "59.99",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Hot Wheels Action Rollout Raceway Trackset",
                  "image": "https://m.media-amazon.com/images/I/71I-fGpD7xS._AC_SL1500_.jpg",
                  "description": "Das Hot Wheels Rollout Raceway Trackset ist Rennbahn und Aufbewahrungsmöglichkeit zugleich. Im zusammengeklappten Zustand ist es eine praktische Aufbewahrungsmöglichkeit für bis zu 80 Autos und fünf Rennbahnen. Wird es ausgeklappt, verwandelt es sich in eine fünfspurige Rennstrecke für mehrere Fahrzeuge. Über der Zielgeraden wird angezeigt,welches Auto das Rennen gewonnen hat. Enthält ein Hot Wheels Fahrzeug im Maßstab 1:64.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.7",
                    "reviewCount": "871"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-hgk41,--rollout-raceway-trackset,-l%C3%A4sst-sich-zu-einer-f%C3%BCnfspurigen-rennstrecke-ausrollen,-aufbewahrungsbeh%C3%A4lter,-spielzeug-autorennbahn-f%C3%BCr-kinder-ab-4-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "30.99",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Track Builder Starter Set",
                  "image": "https://m.media-amazon.com/images/I/71n2aqSywvL._AC_SL1500_.jpg",
                  "description": "Das Track Builder Starter Kit enthält orangefarbene Bahnstücke, einen geteilte Looping, Rampen, zwei 90-Grad-Kurven, mehrere Verbindungsstücke und vieles mehr! Ein guter Start um eigene Bahnen zusammen zubauen. Ein Hot Wheels Auto ist ebenfalls enthalten, damit der Rennspaß direkt nach dem Auspacken beginnt.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.9",
                    "reviewCount": "9"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-bht77,--track-builder-pack-inklusiv-fahrzeug,-spielzeug-autorennbahn-ab-4-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "19.99",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Track Builder Unlimited Triple Loop Kit",
                  "image": "https://m.media-amazon.com/images/I/71tNgFlJBSL._AC_SL1500_.jpg",
                  "description": "Das Track Builder Unlimited Looping ist das erste Set mit drei Stunt-Loopings und bietet aufregende Stunt-Action. Loopings passen gute in jede Bahn und bringen immer viel Spaß.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.0",
                    "reviewCount": "137"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-gyp65,--track-builder-unlimited-triple-loop-kit,-spielzeug-autorennbahn-ab-6-jahren/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "41.84",
                    "priceCurrency": "EUR"
                  }
                },
                {
                  "@type": "Product",
                  "name": "Track Builder Booster Pack",
                  "image": "https://m.media-amazon.com/images/I/71MWsb2l2oL._AC_SL1500_.jpg",
                  "description": "Der erneuerte Power Booster, der die Autos mit hoher Geschwindigkeit über die Bahn rasen lässt. Das Booster-Paket enthält ein Auto, einen Power-Booster und 2 Bahnstücke.",
                  "brand": {
                    "@type": "Brand",
                    "name": "Hot Wheels"
                  },
                  "audience": {
                      "@type": "PeopleAudience",
                      "suggestedGender": "unisex",
                      "suggestedMinAge": 5,
                      "suggestedMaxAge": 14,
                      "age_group": "kids"
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.5",
                    "reviewCount": "4"
                  },
                  "keywords": "Hot Wheels, Garage, Track Set",
                  "offers": {
                    "@type": "Offer",
                    "url": "${"https://www.hotwheels.autos/produkt/hot-wheels-gbn81,--track-builder-booster-pack-spielset,-beschleuniger-mit-2-tracks-und-1-spielzeugauto,-trackset-zubehör,spielzeug-autorennbahn-ab-6-jahren,-mehrfarben/"}",
                    "availability": "https://schema.org/InStock",
                    "price": "23.51",
                    "priceCurrency": "EUR"
                  }
                }
              ]
            }
          ]
        }
          `
        }
      </script>
    </Seo>
      
      <div className='content'>
      <div className='row'>
        <div className=''>
          <h1 style={{marginBottom: 15}}>Die besten Hot Wheels Bahnen in 2022</h1>
          <p>Es gibt 3 verschiedene Reihen von Hot Wheels Bahnen. Für die <b>jüngsten</b> gibt es die Hot Wheels City und dann für ältere Kinder die Hot Wheels Action und für Kinder im Alter von <b>6+</b> gibt es die Hot Wheels Track Builder Bahnsets. <br /> <br />Die verschiedenen <b>Bahnen sind miteinander kompatibel</b> weil immer die gleichen Bahnstücke verwendet werden. Als <b>erstes Spielset</b> eignen sich besonders die <b>Parkgarage</b>, weil diese oft viele verschiedene Möglichkeiten haben andere Erweiterungen über bahnen anzuschließen.</p>
          <h2 id="city" className="border" style={{marginTop: 75}}>Hot Wheels City</h2>
          <p>Hot Wheels City ist eins von 3 Bahnen die 2018 eingeführt wurden. Es richtet sich an die jüngsten Hot Wheels Fans. Die Bahnen zeigen viele Kartoonische Tiere und Elemente. Die Zielgruppe sind Kinder im Alter von <b>3 bis 8 Jahren</b>. </p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/81SrErCcyuS._AC_SL1500_.jpg"
            altText="Super Ultimate Garage" 
            url="https://amzn.to/3Zm0bY6" 
            title="Hot Wheels Super Ultimate Garage"
            video={{
              pos: "bottom",
              width: "350px",
              alignment: "bottom",
              orientation: "row",
              title: "Super Ultimate Garage",
              description: "Das kurze Video ist auf Englisch aber es hilft dir bestimmt einen Eindruck von der Parkgarage zubekommen",
              srcUrl: "https://www.youtube.com/embed/LlnO0bhYoDQ",
              duration: "0 Minuten 41 Sekunden",
              channelLink: "https://www.youtube.com/user/Mattel/videos",
              channelName: "Mattel",
              textAfterVideo: "Wegen der <b>vielen Anschlussmöglichkeiten</b> für andere Bahnen und <b>viel Platz</b> für eine große Sammlung ist diese Garage ein perfekter Start in deiner Sammlung."
            }}
            text="Die größte Hot Wheels Garage, die Sie kaufen können. Es ist über <b>90 cm hoch</b> und kann etwa <b>140 verschiedene Hot Wheels Autos</b> in seinen mehrstöckigen Garagenplätzen unterbringen! Die Garage hat mehrstufige Rennstrecken und einen fantastischen Aufzug, der die Autos auf die oberste Ebene der Garage befördert. Es gibt einen Gorilla, der an der Seite des Aufzugs hochklettert und versucht, Autos aus dem Aufzug zu stoßen."
          />

          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71G5rZoxGTL._AC_SL1500_.jpg" 
            altText="City Robo T-Rex Ultimate Garage"
            url="https://amzn.to/3lJqeL8" 
            title="Hot Wheels City Robo T-Rex Ultimate Garage"
            text="Ist genauso hoch wie die Super Garage hat aber nur Platz für <b>100 Autos</b>. Dafür können sich die Autos auf der Zweispuren Bahn ein Wettrennen nach unten liefern, während sie einem T-Rex ausweichen müssen. Die Rennbahn hat auch mehrere Lücken in der Bahn für <b>coole Luftsprünge</b>."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/61cVrs+DItL._AC_SL1500_.jpg" 
            altText="City Cobra Crush"
            url="https://amzn.to/3FTqkGZ" 
            title="Hot Wheels City Cobra Crush"
            text="Ein cooles <b>Stunt Bahnset</b> um die Stadt zu erweitern. Wie der Name schon sagt, handelt es sich bei dieser Strecke um eine riesige Kobra, auf die man Autos schießt. Die Kobra schließt ihr Maul, um ein Auto zu fangen, und du kannst das gefangene Auto retten, indem du ein Auto auf ihren Rücken schießt und einen Auslöser betätigst, der die Schlange einstürzen und ihr Maul öffnen lässt!"
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/81MiK1QwgVL._AC_SL1500_.jpg" 
            altText="City Ultimate Gator Car Wash"
            url="https://amzn.to/42JxS95" 
            video={{
              pos: "bottom",
              width: "250px",
              alignment: "bottom",
              orientation: "row",
              title: "Ultimate Gator Car Wash",
              description: "Das kurze Video ist auf Englisch aber es hilft dir bestimmt einen Eindruck von der Waschanlage zubekommen",
              srcUrl: "https://www.youtube.com/embed/T4ETytFUTZM",
              duration: "0 Minuten 41 Sekunden",
              channelLink: "https://www.youtube.com/c/hotwheels/videos",
              channelName: "Hot Wheels",
              textAfterVideo: "Wenn du die <b>farbwechselnden Hot Wheels Autos</b> hast, ändern die Autos ihre Farbe, wenn sie durch die Waschanlage fahren."
            }}
            title="Hot Wheels City Ultimate Gator Car Wash"
            text='Die Alligatoren Waschanlage ist eins der <b>coolsten Erweiterungen</b>. Es gibt einen Aufzug, an dem man kurbelt, um das Auto nach oben zu bringen, es gibt einen Rädchen, an dem man drehen kann, damit echtes Wasser auf das Auto fällt, man kann dann das Auto die Strecke hinunter in einen "Whirlpool" schieben, dem man entkommen kann. Es gibt auch einen riesigen Alligator, der bereit ist, das Auto zu fressen!'
          />
          
          <h2 id="action" className="border" style={{marginTop: 75}}>Hot Wheels Action</h2>
          <p>Die Hot Wheels Action-Bahnen sind für Kinder im Alter von <b>4 bis 10 Jahren</b> gedacht. Die Sets verfügen über komplexere Mechanismen und sind in der Regel nicht auf ein bestimmtes Thema ausgerichtet. Zahlreiche frühere Sets, die für diese Altersgruppe geeignet waren, wurden ebenfalls in das Action-Sortiment aufgenommen.</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71CqE9eToCL._AC_SL1500_.jpg" 
            altText="Action MEGA Crash Superbahn"
            url="https://amzn.to/42IFto2"
            title="Hot Wheels Action MEGA Crash Superbahn"
            text="Die Kinder können ihre Fahrzeuge auf der Bahn starten, und die Fahrzeuge können auf der Bahn kreisen bis sie in den drei verschiedenen Crash-Zonen einem aufregenden Zusammenstoß immer näher kommen. Mit zwei Abschussvorrichtungen können Kinder ihre Freunde herausfordern und weitere Fahrzeuge auf die Strecke bringen, bis sie in einem dramatischen Crash aufeinandertreffen, der zum Spaß dieses High-Speed-Sets gehört. Über eine Falltür in der Bahn kannst das Set mit anderen verbunden werden."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71I-fGpD7xS._AC_SL1500_.jpg" 
            altText="Action Rollout Raceway Trackset"
            url="https://amzn.to/3z7intT" 
            title="Hot Wheels Action Rollout Raceway Trackset"
            text="Das Hot Wheels Rollout Raceway Trackset ist <b>Rennbahn und Aufbewahrungsmöglichkeit</b> zugleich. Im zusammengeklappten Zustand ist es eine praktische Aufbewahrungsmöglichkeit für bis zu <b>80 Autos</b> und fünf Rennbahnen. Wird es ausgeklappt, verwandelt es sich in eine fünfspurige Rennstrecke für mehrere Fahrzeuge. Über der Zielgeraden wird angezeigt,welches Auto das Rennen gewonnen hat. Enthält ein Hot Wheels Fahrzeug im Maßstab 1:64."
          />
         
          
          
          <h2 id="trackbuilder" className="border" style={{marginTop: 75}}>Hot Wheels Track Builder Unlimited</h2>
          <p>Die Hot Wheels Track Builder Sets sind die komplexesten Spielsets von Hot Wheels. Die Mehrzweckteile können in nahezu <b>endlosen Kombinationen</b> verwendet werden, um immer neue Bahnen zu bauen und der Kreativität freien Lauf zu lassen.</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71n2aqSywvL._AC_SL1500_.jpg" 
            altText="Starter set für Bahn"
            url="https://amzn.to/3z7XRJv" 
            title="Track Builder Starter Set"
            text="Das Track Builder Starter Kit enthält orangefarbene Bahnstücke, einen geteilte Looping, Rampen, zwei 90-Grad-Kurven, mehrere Verbindungsstücke und vieles mehr! Ein guter Start um eigene Bahnen zusammen zubauen. Ein Hot Wheels Auto ist ebenfalls enthalten, damit der Rennspaß direkt nach dem Auspacken beginnt."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71tNgFlJBSL._AC_SL1500_.jpg"
            altText="Track Builder Unlimited Triple Loop Kit" 
            url="https://amzn.to/3M9tcUb" 
            title="Track Builder Unlimited Triple Loop Kit"
            text="Das Track Builder Unlimited Looping ist das erste Set mit drei Stunt-Loopings und bietet aufregende Stunt-Action. Loopings passen gute in jede Bahn und bringen immer viel Spaß."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71MWsb2l2oL._AC_SL1500_.jpg"
            altText="Track Builder Booster Pack" 
            url="https://amzn.to/3LQsmLG" 
            title="Track Builder Booster Pack"
            text="Der erneuerte Power Booster, der die Autos mit hoher Geschwindigkeit über die Bahn rasen lässt. Das Booster-Paket enthält ein Auto, einen Power-Booster und 2 Bahnstücke."
          />
        </div>
        <div className='content-list-wrapper' style={{width: 240, minWidth: 240, marginLeft: 25}}>
          <Inhaltsverzeichnis content={[
            {
              id: "city",
              text: "City",
            },
            {
              id: "action",
              text: "Action"
            }, 
            {
              id: "trackbuilder",
              text: "Track Builder"
            }
          ]
          }/>
        </div>
       
      </div>
      
    </div>
    
    
    
   
  </Layout>
)

/*
<div className='row'>
            <img src="" />
            <div className=''>
              <h4></h4>
              <p></p>
            </div>
          </div>

*/
export default Ratgeber
