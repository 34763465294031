import * as React from "react"
import Button from "./button-link"
import Video from './video'

const AmazonItem = ({imgUrl, url, title, text, altText, video}) => {
    //
    if (!video) {
        video = {}
    }
    function captureOutboundLink(url) {
        if (typeof window !== "undefined") {
            if (window.gtag) {
                window.gtag('event', 'blog item click', {
                    'event_category': 'amazon click',
                    'event_label': 'blog item click'
                });
            }
            
        }
      document.location = url;
    }
    return (
        <>
            <div className='amazon-item' style={{margin: "35px 0px"}}>
                {video.alignment === "left" &&
                    <Video video={video} />
                }
                {!video.title &&
                    <img style={{width: 250, marginRight: 25}} src={imgUrl} alt={altText ? altText : title}/>
                }
                <div className=''>
                    <h4>{title}</h4>
                    <p style={{marginBottom: 10}} dangerouslySetInnerHTML={{__html: text}}></p>
                    {video.alignment === "bottom" &&
                        <div>
                            <Video video={video} />
                            {video.textAfterVideo &&
                                <p dangerouslySetInnerHTML={{__html: video.textAfterVideo}} style={{marginBottom: 15}}></p>
                            }
                        </div>
                    }
                    <a onClick={() => captureOutboundLink(url)} className='amazon-continue' rel="nofollow" target={"_blank"} style={{padding: "5px 15px", display: "block", border: "1px solid var(--color-primary)", borderRadius: 2, fontSize: "18px", lineHeight: "37px"}}>Weiter zu Amazon</a>
                </div>
            </div>
        </>
    )
}

export default AmazonItem
